import React from 'react'

export default function TipsPage(props) {
    return (
        <div
            className={
                props.isLoading || props.wasSearched
                    ? 'aboutpage-hide'
                    : 'aboutpage'
            }
        >
            <h2 className="tips-title"></h2>

            <h3 className="searchexample-desc">
                <span className="searchexample-number">1</span>
                Use +@ for searching a specific store.
            </h3>
            <p className="searchexample-desc">
                When you want to find products from a specific store, type:
            </p>
            <p className="searchexample-query">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#bbb"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <span className="searchexample-query-text">
                    lipstick +@kyliecosmetics
                </span>
            </p>

            <h3 className="searchexample-desc">
                <span className="searchexample-number">2</span>Use the minus
                sign to exclude products with a certain keyword.
            </h3>
            <p className="searchexample-desc">
                When you want to find tea (except for jasmine tea), search like
                this:
            </p>
            <p className="searchexample-query">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#bbb"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <span className="searchexample-query-text">tea -jasmine</span>
            </p>

            <p className="searchexample-desc">
                When you want to exclude a @shopname or brand, just subtract
                again:
            </p>

            <p className="searchexample-query">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#bbb"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <span className="searchexample-query-text">
                    tea -jasmine -@shop.name -brand
                </span>
            </p>

            <h3 className="searchexample-desc">
                <span className="searchexample-number">3</span>Use the plus sign
                to only show products with a certain keyword.
            </h3>
            <p>When you want to find loose leaf tea that isn't jasmine:</p>

            <p className="searchexample-query">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#bbb"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <span className="searchexample-query-text">
                    tea -jasmine +"loose leaf"
                </span>
            </p>

            <h3 className="searchexample-desc">
                <span className="searchexample-number">4</span>
                Use double quotes when the order of keywords matter.
            </h3>

            <p className="searchexample-query">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#bbb"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <span className="searchexample-query-text">"pizza oven"</span>
            </p>
        </div>
    )
}
