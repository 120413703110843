import config from './engine.json'
import secret from './secret.json'

export function getConfig() {
    if (config.engineName) return config
}

export function getSecret() {
    if (secret.searchKey) return secret
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function buildFacetConfigFromConfig() {
    const config = getConfig()

    const facets = (config.facets || []).reduce((acc, n) => {
        if (n === 'min_price') {
            acc[n] = {
                type: 'range',
                ranges: [
                    { from: 0, to: 50, name: '$' },
                    { from: 50, to: 500, name: '$$' },
                    { from: 500, to: 2000, name: '$$$' },
                    { from: 2000, name: '$$$$' },
                ],
            }
            return acc
        }

        acc = acc || {}
        acc[n] = {
            type: 'value',
            size: 100,
        }
        return acc
    }, undefined)

    return facets
}

export function getFacetFields() {
    return getConfig().facets || []
}

export function buildSortOptionsFromConfig() {
    const config = getConfig()
    return [
        {
            name: 'Relevance',
            value: '',
            direction: '',
        },
        ...(config.sortFields || []).reduce((acc, sortField) => {
            acc.push({
                name: `${capitalizeFirstLetter(sortField)} ASC`,
                value: sortField,
                direction: 'asc',
            })
            acc.push({
                name: `${capitalizeFirstLetter(sortField)} DESC`,
                value: sortField,
                direction: 'desc',
            })
            return acc
        }, []),
    ]
}

export function getSortFields() {
    return getConfig().sortFields || []
}
