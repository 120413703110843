import React from 'react'

export default function Waitlist(props) {
    function createCookie(name, value) {
        /*document.cookie =
            'alreadAsked=true; expires=Thu, 18 Dec 2099 12:00:00 UTC'
		*/
        var date = new Date()
        date.setTime(date.getTime() + 4 * 1000)
        var expires = '; expires=' + date.toGMTString()

        document.cookie = name + '=' + value + expires + '; path=/'
    }

    const [actionword, setActionword] = React.useState('Creating')

    const [phonenumber, setPhonenumber] = React.useState('')

    const [sent, setSent] = React.useState(false)

    const [loading, setLoading] = React.useState(false)

    function handlePhoneChange(e) {
        setPhonenumber(e.target.value)
    }

    function handleSubmitClick(e) {
        if (parseInt(phonenumber).toString().length === 10) {
            createCookie('formSubmitted', phonenumber.toString())
        }

        if (window.innerWidth > 900) {
            if (parseInt(phonenumber).toString().length === 10) {
                setTimeout(myLoadingFunction, 1)

                setTimeout(myActionFunction, Math.random() * (800 - 600) + 600)

                setTimeout(myStopFunction, Math.random() * (2000 - 1700) + 1700)

                function myLoadingFunction() {
                    setLoading((prev) => !prev)
                }

                function myStopFunction() {
                    setSent((prev) => {
                        return !prev
                    })
                }

                function myActionFunction() {
                    setActionword('Saving')
                }
            }
        } else {
            if (parseInt(phonenumber).toString().length === 10) {
                setTimeout(myLoadingFunction, 1)

                setTimeout(myActionFunction, Math.random() * (800 - 600) + 600)

                setTimeout(myStopFunction, Math.random() * (2000 - 1700) + 1700)

                function myLoadingFunction() {
                    setLoading((prev) => !prev)
                }

                function myStopFunction() {
                    setSent((prev) => {
                        return !prev
                    })
                }

                function myActionFunction() {
                    setActionword('Saving')
                }
            }
        }
    }

    if (
        document.cookie.indexOf('formSubmitted') !== -1 &&
        window.innerWidth < 900
    ) {
        const phonenumber =
            document.cookie
                .match('(^|;)\\s*' + 'formSubmitted' + '\\s*=\\s*([^;]+)')
                ?.pop() || ''

        return (
            <div
                className={
                    props.isLoading || props.wasSearched
                        ? 'aboutpage-hide'
                        : 'aboutpage'
                }
            >
                <div className="install-body">
                    <div
                        className={`install-body-heading ${
                            props.isRouted ? 'install-body-heading-routed' : ''
                        }`}
                    >
                        <img
                            src="./apple-touch-icon.png"
                            alt="install-icon"
                            className="install-icon"
                        ></img>
                        <div>
                            <h2>get full access</h2>
                            <p>
                                join the waitlist and get a unique invite code
                                for unlocking exclusive deals!
                            </p>
                        </div>
                    </div>
                    <div className="install-confirmation">
                        <div
                            className={`install-confirmation-done ${
                                props.isRouted
                                    ? 'install-confirmation-done-routed'
                                    : ''
                            }`}
                        >
                            <i className="gg-check"></i>
                            <strong data-success="true" data-error="true">
                                done!
                            </strong>
                        </div>
                        <p>thank you for joining the waitlist. </p>
                        <p>
                            your invite code will be sent to (
                            {phonenumber.slice(0, 3)}) {phonenumber.slice(3, 6)}
                            -{phonenumber.slice(6, 11)} when it's your turn.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            className={
                props.isLoading || props.wasSearched
                    ? 'aboutpage-hide'
                    : 'aboutpage'
            }
        >
            <div className="install-body">
                <div
                    className={`install-body-heading ${
                        props.isRouted ? 'install-body-heading-routed' : ''
                    }`}
                >
                    <img
                        src="./apple-touch-icon.png"
                        alt="install-icon"
                        className="install-icon"
                    ></img>
                    <div>
                        <h2>get full access</h2>
                        <p>
                            join the waitlist and get a unique invite code for
                            unlocking exclusive deals!
                        </p>
                    </div>
                </div>

                <form
                    className={
                        props.isRouted ? 'install-form-routed' : 'install-form'
                    }
                    name="waiting-list"
                    betterform="true"
                >
                    <input
                        type="hidden"
                        name="form[name]"
                        value="Waiting List"
                    />
                    {!sent && (
                        <div className="install-form-input">
                            <label>
                                <div
                                    className="install-flags"
                                    data-success="true"
                                    data-error="true"
                                >
                                    &#127482;&#127480; &#127464;&#127462;
                                </div>
                                <strong>+1</strong>
                                <input
                                    autoCorrect="off"
                                    autoComplete="off"
                                    type="tel"
                                    name="PhoneNumber"
                                    placeholder="6473228801"
                                    pattern="[0-9]{10}"
                                    maxLength="10"
                                    required
                                    className="install-phonenumber"
                                    onChange={handlePhoneChange}
                                    value={phonenumber}
                                />
                            </label>
                        </div>
                    )}
                    <div className="install-form-input">
                        {!sent && (
                            <button
                                type="submit"
                                className={`install-submitbutton ${
                                    loading
                                        ? 'install-submitbutton-disabled'
                                        : ''
                                }`}
                                onClick={handleSubmitClick}
                            >
                                {loading ? (
                                    <span>
                                        <i className="gg-spinner-alt-small"></i>
                                        {actionword} your code{' '}
                                    </span>
                                ) : (
                                    'reserve my invite code'
                                )}
                            </button>
                        )}
                        {sent && (
                            <div className="install-confirmation">
                                <div className="install-confirmation-done">
                                    <i className="gg-check"></i>
                                    <strong>done!</strong>
                                </div>
                                <p>thank you for joining the waitlist. </p>
                                <p>
                                    your invite code will be sent to (
                                    {phonenumber.slice(0, 3)}){' '}
                                    {phonenumber.slice(3, 6)}-
                                    {phonenumber.slice(6, 11)} when it's your
                                    turn.
                                </p>
                            </div>
                        )}
                    </div>
                    {/*
                    <a href="https://betterform.io" target="_blank">
                        <img
                            src="https://betterform.io/betterform-handler-light.svg"
                            alt="Handled by betterform"
                            height="11px"
                            width="127px"
                        />
                    </a> */}
                </form>
            </div>
        </div>
    )
}
