import React from 'react'
import toast from 'react-hot-toast'

export default function LoginPage(props) {
    const [loading, setLoading] = React.useState(false)

    function handleSubmitClick(e) {
        setTimeout(myLoadingFunction, 1)

        setTimeout(myStopFunction, Math.random() * (1900 - 1600) + 350)

        function myLoadingFunction() {
            setLoading((prev) => !prev)
        }

        function myStopFunction() {
            setLoading((prev) => !prev)
            toast(`Please enter a valid invite code`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    fontWeight: 'bold',
                },
            })
        }
    }

    return (
        <div
            className={
                props.isLoading || props.wasSearched
                    ? 'aboutpage-hide'
                    : 'aboutpage'
            }
        >
            <div className="login-section">
                <h3 className="tips-title">Please enter your invite code</h3>
                <form>
                    <div>
                        <input
                            autocorrect="off"
                            name="invitecode"
                            autocomplete="off"
                            type="text"
                            placeholder="Ah2k6nJa3dRg9kXp"
                            className="install-phonenumber login-email signup-invitecode"
                        ></input>
                    </div>
                    <div>
                        <input
                            autocorrect="off"
                            name="email"
                            autocomplete="off"
                            type="text"
                            placeholder="email"
                            className="install-phonenumber login-email signup-email"
                        ></input>
                    </div>
                    <div>
                        <input
                            name="pwd"
                            autocorrect="off"
                            autocomplete="off"
                            type="password"
                            placeholder="password"
                            className="install-phonenumber login-pwd"
                        ></input>
                    </div>
                    <div>
                        <span
                            className={`install-submitbutton ${
                                loading ? 'install-submitbutton-disabled' : ''
                            }`}
                            onClick={handleSubmitClick}
                        >
                            {loading ? (
                                <span>
                                    <i className="gg-spinner-alt-small"></i>
                                    Validating...
                                </span>
                            ) : (
                                'Sign up'
                            )}
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}
