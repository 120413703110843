import { useEffect, useState } from 'react'

export default function SuggestedKeywords(props) {
    const [keywords, setKeywords] = useState([])
    const CSS_COLOR_NAMES = [
        'AliceBlue',
        'AntiqueWhite',
        'Aqua',
        'Aquamarine',
        'Azure',
        'Beige',
        'Bisque',
        'Black',
        'BlanchedAlmond',
        'Blue',
        'BlueViolet',
        'Brown',
        'BurlyWood',
        'CadetBlue',
        'Chartreuse',
        'Chocolate',
        'Coral',
        'CornflowerBlue',
        'Cornsilk',
        'Crimson',
        'Cyan',
        'DarkBlue',
        'DarkCyan',
        'DarkGoldenRod',
        'DarkGray',
        'DarkGrey',
        'DarkGreen',
        'DarkKhaki',
        'DarkMagenta',
        'DarkOliveGreen',
        'DarkOrange',
        'DarkOrchid',
        'DarkRed',
        'DarkSalmon',
        'DarkSeaGreen',
        'DarkSlateBlue',
        'DarkSlateGray',
        'DarkSlateGrey',
        'DarkTurquoise',
        'DarkViolet',
        'DeepPink',
        'DeepSkyBlue',
        'DimGray',
        'DimGrey',
        'DodgerBlue',
        'FireBrick',
        'FloralWhite',
        'ForestGreen',
        'Fuchsia',
        'Gainsboro',
        'GhostWhite',
        'Gold',
        'GoldenRod',
        'Gray',
        'Grey',
        'Green',
        'GreenYellow',
        'HoneyDew',
        'HotPink',
        'IndianRed',
        'Indigo',
        'Ivory',
        'Khaki',
        'Lavender',
        'LavenderBlush',
        'LawnGreen',
        'LemonChiffon',
        'LightBlue',
        'LightCoral',
        'LightCyan',
        'LightGoldenRodYellow',
        'LightGray',
        'LightGrey',
        'LightGreen',
        'LightPink',
        'LightSalmon',
        'LightSeaGreen',
        'LightSkyBlue',
        'LightSlateGray',
        'LightSlateGrey',
        'LightSteelBlue',
        'LightYellow',
        'Lime',
        'LimeGreen',
        'Linen',
        'Magenta',
        'Maroon',
        'MediumAquaMarine',
        'MediumBlue',
        'MediumOrchid',
        'MediumPurple',
        'MediumSeaGreen',
        'MediumSlateBlue',
        'MediumSpringGreen',
        'MediumTurquoise',
        'MediumVioletRed',
        'MidnightBlue',
        'MintCream',
        'MistyRose',
        'Moccasin',
        'NavajoWhite',
        'Navy',
        'OldLace',
        'Olive',
        'OliveDrab',
        'Orange',
        'OrangeRed',
        'Orchid',
        'PaleGoldenRod',
        'PaleGreen',
        'PaleTurquoise',
        'PaleVioletRed',
        'PapayaWhip',
        'PeachPuff',
        'Peru',
        'Pink',
        'Plum',
        'PowderBlue',
        'Purple',
        'RebeccaPurple',
        'Red',
        'RosyBrown',
        'RoyalBlue',
        'SaddleBrown',
        'Salmon',
        'SandyBrown',
        'SeaGreen',
        'SeaShell',
        'Sienna',
        'Silver',
        'SkyBlue',
        'SlateBlue',
        'SlateGray',
        'SlateGrey',
        'Snow',
        'SpringGreen',
        'SteelBlue',
        'Tan',
        'Teal',
        'Thistle',
        'Tomato',
        'Turquoise',
        'Violet',
        'Wheat',
        'White',
        'WhiteSmoke',
        'Yellow',
        'YellowGreen',
    ]

    const sizes = [
        'large',
        'small',
        'medium',
        'wide',
        'narrow',
        'high',
        'low',
        'tall',
        'short',
    ]

    function capitalize(str) {
        if (str === undefined) return ''
        const lower = str.toLowerCase()
        return str.charAt(0).toUpperCase() + lower.slice(1)
    }

    useEffect(() => {
        if (props.isLoading === true) {
            //track fetch calls (100K per day), so we need an eventTracker call here
            //backend call to api.saucefeed.com that implements all the logic (datamuse + redis)

            //colors get excluded from fetch call
            let tempKeyword = props.searchTerm
                .toLowerCase()
                .split(' ')
                .filter(
                    (keyword) =>
                        keyword[0] !== '+' &&
                        keyword[0] !== '-' &&
                        !CSS_COLOR_NAMES.includes(capitalize(keyword)) &&
                        !sizes.includes(keyword)
                )
                .join('+')

            if (localStorage.getItem(tempKeyword) === null) {
                fetch(`https://api.datamuse.com/words?ml=${tempKeyword}&max=7`)
                    .then((res) => res.json())
                    .then((data) => {
                        localStorage.setItem(tempKeyword, JSON.stringify(data))
                        setKeywords(data)
                    })
                    .catch((err) => console.log(err))
            } else {
                let temp = localStorage.getItem(tempKeyword)
                setKeywords(JSON.parse(temp))
            }
        }
    }, [props.isLoading])

    let temp = keywords.map((keyword) => (
        <a
            href={`/?q=${keyword.word}&size=n_58_n`}
            key={keyword.word}
            className="suggestedKeywords-container-list-item"
        >
            <span>
                <svg
                    className="suggestedKeywords-logo"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#65b16e"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>

                {keyword.word}
            </span>
        </a>
    ))

    return props.wasSearched && keywords.length > 0 ? (
        <div className="suggestedKeywords-container">
            <h3>Related searches</h3>
            <div className="suggestedKeywords-container-list">{temp}</div>
        </div>
    ) : (
        <span></span>
    )
}
