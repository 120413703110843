import React from 'react'
import { ChatWindow } from '@papercups-io/chat-widget'
import SimpleLoadingBar from 'react-simple-loading-bar'

export default function TeamPage(props) {
    const [loadingChat, setLoadingChat] = React.useState(true)

    return (
        <div
            className={
                props.isLoading || props.wasSearched
                    ? 'aboutpage-hide chatpage'
                    : 'aboutpage chatpage'
            }
        >
            {loadingChat ? (
                <h2 className="loadingChatHeading">
                    Connecting <i className="gg-spinner-alt"></i>
                </h2>
            ) : (
                ''
            )}
            <SimpleLoadingBar
                activeRequests={Number(loadingChat)}
            ></SimpleLoadingBar>

            <ChatWindow
                // `accountId` is used instead of `token` in older versions
                // of the @papercups-io/chat-widget package (before v1.2.x).
                // You can delete this line if you are on the latest version.
                // accountId="526b7be7-056c-413a-b756-50ec529a405b"
                token="c2af0181-6b88-41e6-bdf1-3ee133ea3af6"
                inbox="f1f1d966-9295-4f6c-a2cd-1490bc178e5a"
                title="How can we help you?"
                subtitle="Typically replies in a few hours"
                primaryColor="#9d9cff"
                greeting="Ask us anything! Please leave your email address if you prefer an email response."
                newMessagePlaceholder="Start typing..."
                newMessagesNotificationText="New Message!"
                showAgentAvailability={false}
                agentAvailableText="We're online right now!"
                customIconUrl="https://photos.merchlook.com/about/chat5.png"
                agentUnavailableText="We're away at the moment."
                requireEmailUpfront={false}
                baseUrl="https://livechattybox.shops.saucefeed.com"
                onChatOpened={() => {
                    setLoadingChat((prev) => !prev)
                }}
                // Optionally include data about your customer here to identify them
                // customer={{
                //   name: __CUSTOMER__.name,
                //   email: __CUSTOMER__.email,
                //   external_id: __CUSTOMER__.id,
                //   metadata: {
                //     plan: "premium"
                //   }
                // }}
            />
        </div>
    )
}
