function run({ vendor, body_short, title }, shop_title) {
    title = title.replace(/#(.*)/gi, '')
    title = title.replace(/\w*\d+.*?\s?|\d+.*?$/gi, '')
    title = title.replace(/\s-.*/i, '')
    title = title.replace(/\(.*\)/i, '')
    title = title.replace(/[^a-z\s\/\-]/gi, '')
    title = title.replace(/\/|\-/gi, ' ')
    title = title.replace(/bundle|preorder|pre-order|refill/i, '')
    title = title.replace(/set of /i, '')
    title = title.replace(/\sin \w* ?[\w*]*$/i, '')
    title = title.replace(/oz|Oz|ml|mL|pcs|spf|pa$|ex/gi, '')
    title = title.replace(/for \w* ?[\w*]*$/i, '')

    title = title.split(' ')

    let finalTitle = []

    title.forEach((item, index, array) => {
        if (item !== '' && item.length > 1 && item !== shop_title) {
            finalTitle.push(item)
        }
    })

    if (finalTitle.slice(-3)[1]) {
        if (finalTitle.slice(-3)[1].match(/\band\b|\bwith\b/i)) {
            return finalTitle.slice(-3).join(' ').toLowerCase()
        }
    }

    //remove 'inches' like from @juliskaofficial
    if (finalTitle[finalTitle.length - 1] === 'in') {
        return finalTitle[finalTitle.length - 2].toLowerCase()
    } else {
        return finalTitle.slice(-2).join(' ').toLowerCase()
    }
}

module.exports['run'] = run
