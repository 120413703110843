import React from 'react'
import toast from 'react-hot-toast'

export default function LoginPage(props) {
    const [loading, setLoading] = React.useState(false)

    function handleSubmitClick(e) {
        setTimeout(myLoadingFunction, 1)

        setTimeout(myStopFunction, Math.random() * (1800 - 1100) + 350)

        function myLoadingFunction() {
            setLoading((prev) => !prev)
        }

        function myStopFunction() {
            setLoading((prev) => !prev)
            toast(`Please enter a valid email`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    fontWeight: 'bold',
                },
            })
        }
    }

    return (
        <div
            className={
                props.isLoading || props.wasSearched
                    ? 'aboutpage-hide'
                    : 'aboutpage'
            }
        >
            <div className="login-section">
                <h3 className="tips-title">
                    Login to unlock full search results
                </h3>
                <form>
                    <div>
                        <input
                            autoCorrect="off"
                            name="email"
                            autoComplete="off"
                            type="text"
                            placeholder="email"
                            className="install-phonenumber login-email"
                        ></input>
                    </div>
                    <div>
                        <input
                            name="pwd"
                            autoCorrect="off"
                            autoComplete="off"
                            type="password"
                            placeholder="password"
                            className="install-phonenumber login-pwd"
                        ></input>
                    </div>
                    <div className="rememberme">
                        <input
                            type="checkbox"
                            id="remembercheck"
                            value="remember"
                        ></input>

                        <label htmlFor="remembercheck">Remember me</label>
                    </div>
                    <div>
                        <span
                            className={`install-submitbutton ${
                                loading ? 'install-submitbutton-disabled' : ''
                            }`}
                            onClick={handleSubmitClick}
                        >
                            {loading ? (
                                <span>
                                    <i className="gg-spinner-alt-small"></i>
                                    Signing In...
                                </span>
                            ) : (
                                'Login'
                            )}
                        </span>
                    </div>
                    <a className="signupjoin-link" href={'/signup'}>
                        Sign up with invite code
                    </a>
                </form>
            </div>
        </div>
    )
}
