import React from 'react'

export default function Footer() {
    return (
        <footer>
            <div className="backtotop">
                <span
                    onClick={() => {
                        window.scrollTo(0, 0)
                    }}
                    style={{ padding: '5px 0' }}
                >
                    <i className="gg-corner-left-up"></i>take me up!
                </span>
            </div>
        </footer>
    )
}
