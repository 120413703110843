import { useRef, useState } from 'react'
import { render } from 'react-dom'
import { Routes, BrowserRouter, Route } from 'react-router-dom'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import {
    PagingInfo,
    Facet,
    WithSearch,
    Paging,
    SearchProvider,
    Results,
    SearchBox,
    Sorting,
} from '@elastic/react-search-ui'
import { Paging as PagingView } from '@elastic/react-search-ui-views'
//import '@elastic/react-search-ui-views/lib/styles/styles.css'

import {
    Sorting as SortingView,
    MultiCheckboxFacet,
    PagingInfo as PagingInfoView,
} from './components/@custom-elastic/react-search-ui-views'

import {
    buildFacetConfigFromConfig,
    buildSortOptionsFromConfig,
    getConfig,
    getSecret,
    getFacetFields,
} from './config/config-helper.js'

import { Toaster } from 'react-hot-toast'
import SimpleLoadingBar from 'react-simple-loading-bar'

import ResultView from './ResultView.js'
import AboutPage from './components/AboutPage'
import TipsPage from './components/TipsPage'
import PageNotFound from './components/PageNotFound'
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import Waitlist from './components/Waitlist'
import ChatPage from './components/ChatPage'
import SuggestedKeywords from './components/SuggestedKeywords'
import LoginPage from './components/Login'
import Signup from './components/Signup'

import { Tzlocator, getBrowserTimezone } from 'tzlocator'

const { engineName } = getConfig()

const { searchKey, endpointBase } = getSecret()

const tzlocator = new Tzlocator()
const location = tzlocator.get(getBrowserTimezone())
let currency = ''

switch (location.currency.code) {
    case 'USD':
        currency = 'USD'
        break
    case 'CAD':
        currency = 'CAD'
        break
    default:
        currency = 'USD'
}

let tempSearchTerm = ''

// variables for price range filter
let minPrice = 0
let maxPrice = 0
let minPriceForPaging = 0
let maxPriceForPaging = 0

// variables for discount filter
let minDiscount = 0
let maxDiscount = 0
let minDiscountForPaging = 0
let maxDiscountForPaging = 0

function App() {
    /* docTitle construciton - mirror in ResultView.js*/
    let currentHref = window.location.href

    let keywordsExist = /q=(.*)&size/.test(currentHref)

    let productTypeExist =
        /product_type&filters%5B0%5D%5Bvalues%5D%5B0%5D=(.*)&filters%5B0%5D%5Btype%5D=all/.test(
            currentHref
        )

    let pageNumberExist = /current=n_/.test(currentHref)

    var docTitleVars = { keywords: '', product_type: '', pageNumber: '' }

    if (keywordsExist) {
        docTitleVars.keywords = decodeURIComponent(
            currentHref.match(/q=(.*)&size/)[1]
        )
    }

    if (pageNumberExist) {
        docTitleVars.pageNumber =
            '- Page ' +
            decodeURIComponent(currentHref.match(/current=n_(.*)_n&/)[1])
    }

    if (productTypeExist) {
        docTitleVars.product_type =
            '- ' +
            decodeURIComponent(
                currentHref.match(
                    /product_type&filters%5B0%5D%5Bvalues%5D%5B0%5D=(.*)&filters%5B0%5D%5Btype%5D=all/
                )[1]
            )
    }

    document.title = `${docTitleVars.keywords} ${docTitleVars.product_type} ${docTitleVars.pageNumber} - Merchlook.com`

    /* docTitle construction END */

    /* list3 construction START */

    // default is "3,6" because those are the positions inside engine.json, so we slice from 6 if we want to show product_type
    var list3_obj = { start: 3, end: 6 }

    if (docTitleVars.keywords.includes('+@')) {
        list3_obj.start = 6
        list3_obj.end = 7
    }

    /* list3 construction END */

    const initialKeywords = [
        'shoulder dress',
        'eye cream',
	'lotion',
        'platform heels',
        'waffle jogger',
        'dining table',
    ]

    let randomInitialKeyword =
        initialKeywords[Math.floor(Math.random() * initialKeywords.length)]

    const searchBar = useRef(null)

    const [userCountry, setUserCountry] = useState(currency)

    const connector = new AppSearchAPIConnector({
        searchKey,
        engineName,
        endpointBase,
    })


    //dont alwaysSearch if pathname matches a route
    const config =
        window.location.pathname.match(
            /login|chat|tips|signup|about|waitlist/
        ) !== null
            ? {
                  searchQuery: {
                      facets: buildFacetConfigFromConfig(),
                      disjunctiveFacets: ['shop_title'],
                      filters: [
                          { field: 'shop_shipping', values: [userCountry] },
                      ],
                  },
                  apiConnector: connector,
                  trackUrlState: true,
                  initialState: {
                      resultsPerPage: 58,
                  },
              }
            : {
                  searchQuery: {
                      facets: buildFacetConfigFromConfig(),
                      disjunctiveFacets: ['shop_title'],
                      filters: [
                          { field: 'shop_shipping', values: [userCountry] },
                      ],
                  },
                  apiConnector: connector,
                  trackUrlState: true,
                  alwaysSearchOnInitialLoad: true,
                  initialState: {
                      searchTerm: randomInitialKeyword,
                      resultsPerPage: 58,
                  },
              }

    function handleMinPriceChange(e) {
        minPrice = Number(e.target.value)
    }

    function handleMaxPriceChange(e) {
        maxPrice = Number(e.target.value)
    }

    function handleMinDiscountChange(e) {
        minDiscount = Number(e.target.value)
    }

    function handleMaxDiscountChange(e) {
        maxDiscount = Number(e.target.value)
    }

    function changePlaceholderText(e) {
        if (e.type === 'focus') {
            searchBar.current.placeholder = `what are you looking for?`

            /* focusing on searchbar will take user to end of string */
            const end = e.currentTarget.value.length
            searchBar.current.scrollLeft = searchBar.current.scrollWidth
            searchBar.current.setSelectionRange(end, end)
            searchBar.current.focus()
        }

        if (e.type === 'blur') {
            searchBar.current.placeholder =
                'search products, brands, @shop.names'
        }
    }

    return (
        <BrowserRouter basename="/">
            <SearchProvider config={config}>
                <WithSearch
                    mapContextToProps={({
                        isLoading,
                        wasSearched,
                        trackClickThrough,
                        searchTerm,
                        setSearchTerm,
                        setSort,
                        clearFilters,
                        setResultsPerPage,
                        setCurrent,
                        setFilter,
                    }) => ({
                        isLoading,
                        wasSearched,
                        setSort,
                        searchTerm,
                        trackClickThrough,
                        setResultsPerPage,
                        clearFilters,
                        setCurrent,
                        setSearchTerm,
                        setFilter,
                    })}
                >
                    {({
                        isLoading,
                        setResultsPerPage,
                        setSort,
                        trackClickThrough,
                        wasSearched,
                        searchTerm,
                        setSearchTerm,
                        setFilter,
                        setCurrent,
                        clearFilters,
                    }) => (
                        <div className="App">
                            <div id="app-top" className={`container-header`}>
                                <Toaster position="bottom-left" />
                                <div className="Header">
                                    <SimpleLoadingBar
                                        activeRequests={Number(isLoading)}
                                    ></SimpleLoadingBar>
                                    <NavBar />
                                    <SearchBox
                                        view={({
                                            onChange,
                                            value,
                                            onSubmit,
                                        }) => (
                                            <form
                                                onSubmit={onSubmit}
                                                action="."
                                                id="searchForm"
                                            >
                                                <input
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    placeholder="search products, brands, @shop.names"
                                                    onFocus={
                                                        changePlaceholderText
                                                    }
                                                    onBlur={
                                                        changePlaceholderText
                                                    }
                                                    id="searchbar1"
                                                    type="search"
                                                    ref={searchBar}
                                                    value={value}
                                                    onChange={(e) =>
                                                        onChange(
                                                            e.currentTarget
                                                                .value
                                                        )
                                                    }
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault()
                                                            if (
                                                                searchTerm ===
                                                                ''
                                                            ) {
                                                                return
                                                            }

                                                            //set the product_type list3_obj if shop@ exists in searchTerm
                                                            if (
                                                                searchTerm.includes(
                                                                    '+@'
                                                                )
                                                            ) {
                                                                list3_obj = {
                                                                    start: 6,
                                                                    end: 7,
                                                                }
                                                            } else {
                                                                //reset list3_obj
                                                                list3_obj = {
                                                                    start: 3,
                                                                    end: 6,
                                                                }
                                                            }

                                                            searchBar.current.blur()

                                                            setSort('', '')
                                                            clearFilters([
                                                                'shop_currency',
                                                            ])

                                                            /* search operator shop@sloanetea 
                                                            var shop_extracted =
                                                                null

                                                            let terms =
                                                                searchTerm.split(
                                                                    ' '
                                                                )

                                                            for (let term of terms) {
                                                                if (
                                                                    /shop@(.*)/.test(
                                                                        term
                                                                    )
                                                                ) {
                                                                    shop_extracted =
                                                                        term.match(
                                                                            /shop@(.*)/
                                                                        )[1]
                                                                }
                                                            }

                                                            if (
                                                                shop_extracted !==
                                                                null
                                                            ) {
                                                                setFilter(
                                                                    'shop_ig_user',
                                                                    shop_extracted
                                                                )
                                                            } else {
                                                                setSearchTerm(
                                                                    searchTerm
                                                                )
                                                            }

                                                            /* search operator code end */
                                                        }
                                                    }}
                                                />

                                                {value && (
                                                    <button
                                                        type="submit"
                                                        id="searchbutton1"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            if (
                                                                searchTerm ===
                                                                ''
                                                            ) {
                                                                return
                                                            }

                                                            //set the product_type list3_obj if shop@ exists in searchTerm
                                                            if (
                                                                searchTerm.includes(
                                                                    '+@'
                                                                )
                                                            ) {
                                                                list3_obj = {
                                                                    start: 6,
                                                                    end: 7,
                                                                }
                                                            } else {
                                                                //reset list3_obj
                                                                list3_obj = {
                                                                    start: 3,
                                                                    end: 6,
                                                                }
                                                            }

                                                            minPriceForPaging = 0
                                                            maxPriceForPaging = 0
                                                            minDiscountForPaging = 0
                                                            maxDiscountForPaging = 0
                                                            tempSearchTerm =
                                                                searchTerm
                                                            setSort('', '')
                                                            clearFilters([
                                                                'shop_currency',
                                                            ])

                                                            /* search operator shop@sloanetea 
                                                            var shop_extracted =
                                                                null

                                                            let terms =
                                                                searchTerm.split(
                                                                    ' '
                                                                )

                                                            for (let term of terms) {
                                                                if (
                                                                    /shop@(.*)/.test(
                                                                        term
                                                                    )
                                                                ) {
                                                                    shop_extracted =
                                                                        term.match(
                                                                            /shop@(.*)/
                                                                        )[1]
                                                                }
                                                            }

                                                            if (
                                                                shop_extracted !==
                                                                null
                                                            ) {
                                                                setFilter(
                                                                    'shop_ig_user',
                                                                    shop_extracted
                                                                )
                                                            } else {
                                                                setSearchTerm(
                                                                    searchTerm
                                                                )
                                                            }

                                                            /* search operator code end */

                                                            /* remove negative operators

                                                            setSearchTerm(
                                                                searchTerm
                                                                    .replace(
                                                                        /(-\w*)/g,
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        /(\s+)/g,
                                                                        ' '
                                                                    )
                                                            )
															*/
                                                        }}
                                                        className={`searchbuttons ${
                                                            isLoading
                                                                ? 'searchbuttons-loading'
                                                                : ''
                                                        }`}
                                                    >
                                                        {isLoading ? (
                                                            <i className="gg-spinner-alt-searchload"></i>
                                                        ) : (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="#000"
                                                                strokeWidth="3"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            >
                                                                <path d="M21.5 2v6h-6M21.34 15.57a10 10 0 1 1-.57-8.38" />
                                                            </svg>
                                                        )}
                                                    </button>
                                                )}
                                                {value && (
                                                    <button
                                                        className="searchbuttons resetbutt"
                                                        onClick={() => {
                                                            onChange('')
                                                            searchBar.current.focus()
                                                        }}
                                                    >
                                                        <i className="gg-close"></i>
                                                    </button>
                                                )}
                                            </form>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="elastic-body">
                                <div className="elastic-body-sorting">
                                    {wasSearched && (
                                        <select
                                            onChange={(event) => {
                                                setUserCountry(
                                                    event.target.value
                                                )
                                                //setCurrent(1)
                                            }}
                                            value={userCountry}
                                            className="select-country"
                                        >
                                            <option value="USD">USD 🇺🇸</option>
                                            <option value="CAD">CAD 🇨🇦</option>
                                        </select>
                                    )}
                                    {wasSearched && (
                                        <Sorting
                                            sortOptions={buildSortOptionsFromConfig()}
                                            view={SortingView}
                                        />
                                    )}
                                </div>

                                <div className="filters-container">
                                    <div
                                        className={
                                            wasSearched
                                                ? 'elastic-body-filters'
                                                : 'elastic-body-filters make-invis'
                                        }
                                    >
                                        <ul className="elastic-body-filters-list1">
                                            {/* slice to get is_onsale, is_available, shop_currency INDIVIDUALLY */}
                                            {getFacetFields()
                                                .slice(0, 3)
                                                .map((field) => (
                                                    <li key={field}>
                                                        <div className="elastic-body-filters-cards">
                                                            <Facet
                                                                filterType="any"
                                                                key={field}
                                                                field={field}
                                                                label={field}
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                show={58}
                                                                view={
                                                                    MultiCheckboxFacet
                                                                }
                                                            />
                                                        </div>
                                                    </li>
                                                ))}

                                            <li className="discount-filter-li">
                                                <span className="filter-label">
                                                    discount
                                                </span>
                                                <input
                                                    placeholder="min"
                                                    type="number"
                                                    onChange={
                                                        handleMinDiscountChange
                                                    }
                                                    id="minDiscountInput"
                                                />
                                                <span>–</span>
                                                <input
                                                    placeholder="max"
                                                    type="number"
                                                    onChange={
                                                        handleMaxDiscountChange
                                                    }
                                                    id="maxDiscountInput"
                                                />
                                                <button
                                                    id="discountRangeSubmit"
                                                    onClick={(e) => {
                                                        // recover the searchTerm if user removes it from searchbar
                                                        if (searchTerm === '') {
                                                            setSearchTerm(
                                                                tempSearchTerm
                                                            )
                                                        }

                                                        // dont do anything if conditions dont make sense
                                                        if (
                                                            minDiscount >
                                                                maxDiscount &&
                                                            maxDiscount !== 0
                                                        ) {
                                                            document.getElementById(
                                                                'minDiscountInput'
                                                            ).value = ''
                                                            document.getElementById(
                                                                'maxDiscountInput'
                                                            ).value = ''
                                                            minDiscount = 0
                                                            maxDiscount = 0

                                                            return
                                                        }

                                                        // if user only inputs a minPrice and leaves maxPrice empty
                                                        if (
                                                            minDiscount >
                                                                maxDiscount &&
                                                            maxDiscount === 0
                                                        ) {
                                                            minDiscountForPaging =
                                                                minDiscount
                                                            maxDiscountForPaging =
                                                                maxDiscount

                                                            setFilter(
                                                                'discount_percent',
                                                                {
                                                                    name: 'shopdiscount',
                                                                    from:
                                                                        minDiscount /
                                                                        100,
                                                                }
                                                            )

                                                            document.getElementById(
                                                                'minDiscountInput'
                                                            ).value = ''
                                                            document.getElementById(
                                                                'maxDiscountInput'
                                                            ).value = ''
                                                            minDiscount = 0
                                                            maxDiscount = 0

                                                            return
                                                        }

                                                        // if user enters 2 negative numbers
                                                        if (
                                                            minDiscount < 1 &&
                                                            maxDiscount < 1
                                                        ) {
                                                            document.getElementById(
                                                                'minDiscountInput'
                                                            ).value = ''
                                                            document.getElementById(
                                                                'maxDiscountInput'
                                                            ).value = ''
                                                            minDiscount = 0
                                                            maxDiscount = 0

                                                            return
                                                        }

                                                        minDiscountForPaging =
                                                            minDiscount
                                                        maxDiscountForPaging =
                                                            maxDiscount

                                                        setFilter(
                                                            'discount_percent',
                                                            {
                                                                name: 'shopdiscount',
                                                                from:
                                                                    minDiscount /
                                                                    100,
                                                                to:
                                                                    maxDiscount /
                                                                    100,
                                                            }
                                                        )
                                                        document.getElementById(
                                                            'minDiscountInput'
                                                        ).value = ''
                                                        document.getElementById(
                                                            'maxDiscountInput'
                                                        ).value = ''
                                                        minDiscount = 0
                                                        maxDiscount = 0
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#3da831"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <circle
                                                            cx="11"
                                                            cy="11"
                                                            r="8"
                                                        ></circle>
                                                        <line
                                                            x1="21"
                                                            y1="21"
                                                            x2="16.65"
                                                            y2="16.65"
                                                        ></line>
                                                        <line
                                                            x1="11"
                                                            y1="8"
                                                            x2="11"
                                                            y2="14"
                                                        ></line>
                                                        <line
                                                            x1="8"
                                                            y1="11"
                                                            x2="14"
                                                            y2="11"
                                                        ></line>
                                                    </svg>
                                                </button>
                                            </li>
                                            <li className="price-filter-li">
                                                <span className="filter-label">
                                                    price
                                                </span>
                                                <input
                                                    placeholder="min"
                                                    type="number"
                                                    onChange={
                                                        handleMinPriceChange
                                                    }
                                                    id="minPriceInput"
                                                />
                                                <span>–</span>

                                                <input
                                                    placeholder="max"
                                                    type="number"
                                                    onChange={
                                                        handleMaxPriceChange
                                                    }
                                                    id="maxPriceInput"
                                                />
                                                <button
                                                    id="priceRangeSubmit"
                                                    onClick={(e) => {
                                                        // recover the searchTerm if user removes it from searchbar
                                                        if (searchTerm === '') {
                                                            setResultsPerPage(
                                                               58
                                                            )
                                                            setSearchTerm(
                                                                tempSearchTerm
                                                            )
                                                        }

                                                        // dont do anything if conditions dont make sense
                                                        if (
                                                            minPrice >
                                                                maxPrice &&
                                                            maxPrice !== 0
                                                        ) {
                                                            document.getElementById(
                                                                'minPriceInput'
                                                            ).value = ''
                                                            document.getElementById(
                                                                'maxPriceInput'
                                                            ).value = ''
                                                            minPrice = 0
                                                            maxPrice = 0

                                                            return
                                                        }

                                                        // if user only inputs a minPrice and leaves maxPrice empty
                                                        if (
                                                            minPrice >
                                                                maxPrice &&
                                                            maxPrice === 0
                                                        ) {
                                                            minPriceForPaging =
                                                                minPrice
                                                            maxPriceForPaging =
                                                                maxPrice

                                                            setFilter(
                                                                'min_price',
                                                                {
                                                                    name: 'minonly',
                                                                    from: minPrice,
                                                                }
                                                            )

                                                            document.getElementById(
                                                                'minPriceInput'
                                                            ).value = ''
                                                            document.getElementById(
                                                                'maxPriceInput'
                                                            ).value = ''
                                                            minPrice = 0
                                                            maxPrice = 0

                                                            return
                                                        }

                                                        // if user enters 2 negative numbers
                                                        if (
                                                            minPrice < 1 &&
                                                            maxPrice < 1
                                                        ) {
                                                            document.getElementById(
                                                                'minPriceInput'
                                                            ).value = ''
                                                            document.getElementById(
                                                                'maxPriceInput'
                                                            ).value = ''
                                                            minPrice = 0
                                                            maxPrice = 0

                                                            return
                                                        }

                                                        minPriceForPaging =
                                                            minPrice
                                                        maxPriceForPaging =
                                                            maxPrice

                                                        setFilter(
                                                            `min_price_${userCountry.toLowerCase()}`,
                                                            {
                                                                name: 'mintomax',
                                                                from: minPrice,
                                                                to: maxPrice,
                                                            }
                                                        )
                                                        document.getElementById(
                                                            'minPriceInput'
                                                        ).value = ''
                                                        document.getElementById(
                                                            'maxPriceInput'
                                                        ).value = ''
                                                        minPrice = 0
                                                        maxPrice = 0
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#615674"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <circle
                                                            cx="11"
                                                            cy="11"
                                                            r="8"
                                                        ></circle>
                                                        <line
                                                            x1="21"
                                                            y1="21"
                                                            x2="16.65"
                                                            y2="16.65"
                                                        ></line>
                                                        <line
                                                            x1="11"
                                                            y1="8"
                                                            x2="11"
                                                            y2="14"
                                                        ></line>
                                                        <line
                                                            x1="8"
                                                            y1="11"
                                                            x2="14"
                                                            y2="11"
                                                        ></line>
                                                    </svg>
                                                </button>
                                            </li>
                                        </ul>

                                        <ul className="elastic-body-filters-list3">
                                            {getFacetFields()
                                                .slice(
                                                    list3_obj.start,
                                                    list3_obj.end
                                                )
                                                .map((field) => (
                                                    <li key={field}>
                                                        <div className="elastic-body-filters-cards">
                                                            <Facet
                                                                filterType="any"
                                                                key={field}
                                                                field={field}
                                                                label={field}
                                                                show={58}
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                view={
                                                                    MultiCheckboxFacet
                                                                }
                                                            />
                                                        </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>

                                {wasSearched && (
                                    <PagingInfo
                                        view={(props) =>
                                            PagingInfoView({
                                                ...props,
                                                minprice: minPriceForPaging,
                                                maxprice: maxPriceForPaging,
                                                mindiscount:
                                                    minDiscountForPaging,
                                                maxdiscount:
                                                    maxDiscountForPaging,
                                            })
                                        }
                                    />
                                )}
                                <div
                                    className={
                                        isLoading
                                            ? 'results-loading'
                                            : 'results-static'
                                    }
                                >
                                    <Results
                                        titleField={getConfig().titleField}
                                        urlField={getConfig().urlField}
                                        resultView={(props) =>
                                            ResultView({
                                                ...props,
                                                setSearchTerm: setSearchTerm,
                                                searchTerm: searchTerm,
                                                userCountry: userCountry,
                                                trackClickThrough:
                                                    trackClickThrough,
                                                setFilter: setFilter,
                                            })
                                        }
                                    />
                                    <SuggestedKeywords
                                        isLoading={isLoading}
                                        wasSearched={wasSearched}
                                        searchTerm={searchTerm}
                                        setSearchTerm={setSearchTerm}
                                    />
                                </div>
                                <div className="elastic-body-paging-container">
                                    <Paging
                                        view={(props) =>
                                            PagingView({
                                                ...props,
                                                onChange: (value) => {
                                                    window.scrollTo(0, 0)
                                                    return props.onChange(value)
                                                },
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <Routes>
                                <Route path="/" element={<span></span>}></Route>
                                <Route
                                    path="/waitlist"
                                    element={
                                        <Waitlist
                                            isRouted={true}
                                            wasSearched={wasSearched}
                                            isLoading={isLoading}
                                        />
                                    }
                                ></Route>
                                <Route
                                    path="/login"
                                    element={
                                        <LoginPage
                                            isLoading={isLoading}
                                            wasSearched={wasSearched}
                                        />
                                    }
                                ></Route>
                                <Route
                                    path="/signup"
                                    element={
                                        <Signup
                                            isLoading={isLoading}
                                            wasSearched={wasSearched}
                                        />
                                    }
                                ></Route>
                                <Route
                                    path="/tips"
                                    element={
                                        <TipsPage
                                            isLoading={isLoading}
                                            wasSearched={wasSearched}
                                        />
                                    }
                                ></Route>
                                <Route
                                    path="/chat"
                                    element={
                                        <ChatPage
                                            isLoading={isLoading}
                                            wasSearched={wasSearched}
                                        />
                                    }
                                ></Route>
                                <Route
                                    path="/about"
                                    element={
                                        <AboutPage
                                            isLoading={isLoading}
                                            wasSearched={wasSearched}
                                        />
                                    }
                                ></Route>
                                <Route
                                    path="*"
                                    element={
                                        <PageNotFound
                                            isLoading={isLoading}
                                            wasSearched={wasSearched}
                                        />
                                    }
                                ></Route>
                            </Routes>
                        </div>
                    )}
                </WithSearch>
            </SearchProvider>
            <Footer />
        </BrowserRouter>
    )
}

// ========================================

render(<App />, document.getElementById('root'))
