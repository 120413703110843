import React from 'react'

export default function PageNotFound(props) {
    return (
        <div
            className={
                props.isLoading || props.wasSearched
                    ? 'aboutpage-hide'
                    : 'aboutpage'
            }
        >
            <h2>&#9888;&#65039; sorry, page not found</h2>
        </div>
    )
}
